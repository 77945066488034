import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC4pB6GKeD1TOhhxC3yBVuvWs_U8ebXAPY",
  authDomain: "cs-chat-app-45b50.firebaseapp.com",
  projectId: "cs-chat-app-45b50",
  storageBucket: "cs-chat-app-45b50.appspot.com",
  messagingSenderId: "99284141654",
  appId: "1:99284141654:web:8ada8580988120f6eb5df7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const storage = getStorage()
export const db = getFirestore()